$(document).ready(function () {


    $(".pricing-comparison").on("click", ".compare-toggle-cont", function () {

        // if the plus sign is visible, then show the minus sign and hide the plus sign
        if ($(this).find(".plus").is(":visible")) {
            $(this).find(".plus").hide()
            $(this).find(".minus").show()
            // get the data attribute hide, and set that value to the .compare-text text
            $(this).find(".compare-text").text($(this).find(".compare-plan-features").attr("data-hide"));
            $(".pricing-comparison-actual").show();

        } else {
            $(this).find(".plus").show()
            $(this).find(".minus").hide()
            // get the data attribute show, and set that value to the .compare-text text
            $(this).find(".compare-text").text($(this).find(".compare-plan-features").attr("data-show"));
            $(".pricing-comparison-actual").hide();
        }
    });

    // Responsive pricing table JS

    $(".plan-buttons").on("click", "li", function () {
        var pos = $(this).index() + 2;
        $("tr")
            .find("td:not(:eq(0))")
            .hide();
        $("td:nth-child(" + pos + ")").css("display", "table-cell");
        $("tr")
            .find("th:not(:eq(0))")
            .hide();
        $("li").removeClass("active");
        $(this).addClass("active");

        $(".plans-col").hide();
        // get the selector data-plan on the button sub element
        $plan = $(this).find("button").data("plan");
        $(".plan-" + $plan).show();

    });

    // Initialize the media query
    //var mediaQuery = window.matchMedia("(min-width: 640px)");
    var mediaQuery = window.matchMedia("(min-width: 988px)");

    // Add a listen event
    mediaQuery.addListener(doSomething);

    // Function to do something with the media query
    function doSomething(mediaQuery) {
        if (mediaQuery.matches) {
            $(".sep").attr("colspan", 4);
            // show all the plan boxes
            $(".plans-col").show();
        } else {
            $(".sep").attr("colspan", 2);
            // hide all the plan boxes
            $(".plans-col").hide();
            // show the first plan box
            $(".plans-col:first-child").show();
            // remove the active class from all the buttons
            $(".plan-buttons li").removeClass("active");
            // add the active class to the first button
            //$(".plan-buttons li:first-child").addClass("active");
            $(".plan-buttons li:first-child").click();
        }
    }

    // On load
    doSomething(mediaQuery);


});