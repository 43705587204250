/*
import Vuelidate from 'vuelidate'

if (window.location.pathname == '/signup') {

    // Include our view Component
    window.Vue.component('signup-form', require('../components/SignupForm.vue').default);
    window.Vue.use(Vuelidate);

    const app = new window.Vue({
      el: '#signupFormWpr',
    });

}
*/


document.addEventListener('DOMContentLoaded', function () {
    const form = document.getElementById('wizard-form');
    const backButton = document.getElementById('backButton');

    backButton.addEventListener('click', function () {
        // Temporarily remove the 'required' attribute from required fields
        const requiredFields = form.querySelectorAll('[required]');
        requiredFields.forEach(function (field) {
            field.removeAttribute('required');
        });

        // Submit the form
        form.submit();

        // Restore the 'required' attribute after form submission
      /*  requiredFields.forEach(function (field) {
            field.setAttribute('required', 'required');
        });*/
    });
});