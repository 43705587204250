import Inputmask from "inputmask";

//if (window.location.pathname == '/contact' || window.location.pathname == '/new/contact') {
if (window.location.pathname == '/contact' || window.location.pathname == '/new/contact' || window.location.pathname == '/get-pricing-and-sell-auto-parts-online' || window.location.pathname == '/new/get-pricing-and-sell-auto-parts-online') {

    $(function () {


        //$('#message').attr('placeholder', $('#message-placeholder').data().placeholder);
       // $('#message').attr('placeholder', "Tell us in at least two paragraphs what you are looking to do. " + '\n\n' + "\u2022 Do you need fulfillment from warehouse distributors? " + '\n\n' + "\u2022 What kind of parts are you looking to sell?" + '\n\n' + "\u2022 Are you a manufacturer that has their own data?");

        /*
        var placeholder = $('#message-placeholder').data().placeholder;

        $('#message').val(placeholder);

        $('#message').focus(function(){
            if($(this).val() === placeholder){
                $(this).val('');
            }
        });

        $('#message').blur(function(){
            if($(this).val() === ''){
                $(this).val(placeholder);
            }
        });
        */

        $("#contact_form").validate({
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;

                $('html, body').animate({
                    scrollTop: ($(validator.errorList[0].element).offset().top  - 130)
                }, 500);

            }
        });


        // perform a final check and gray out the submit button when submitted
        // then, hand over validation control to google captcha
        $('#contact_form').submit(function () {

            $('#contact_message_error').hide();
            var message = $('#message').val();
            if (message.length < contactMessageMinChars) {
                $('#contact_message_error').show();
                return false;
            }

            event.preventDefault(); //prevent form submit before captcha is completed
            $('.send-button').prop('disabled', true);
            $('.send-button').html('Sending Message...');

            grecaptcha.execute();

        });

        // init phone # input mask
        Inputmask("(999) 999-9999").mask(document.getElementById('phone'));


        /*$('#message').on('keyup', function () {
            var contactMessageLen = this.value.length
            alertRemainingContactMessage(contactMessageLen);
        })
        $('#message').on('focus', function () {
            var contactMessageLen = this.value.length
            alertRemainingContactMessage(contactMessageLen);
        })
        $('#message').on('change', function () {
            var contactMessageLen = this.value.length
            alertRemainingContactMessage(contactMessageLen);
        })
         */
        $('#message').on('input', function () {
            var contactMessageLen = this.value.length
            alertRemainingContactMessage(contactMessageLen);
        })

        /*$('#contact_form').on('submit', function () {
            $('#contact_message_error').hide();
            var message = $('#message').val();
            if (message.length < contactMessageMinChars) {
                $('#contact_message_error').show();
                return false;
            }
            return true;
        })

         */


        /*
        // Phone # Validation
        var contactPhoneField = $('#phone');
        contactPhoneField.on('keyup', function() {
            var value = $(this).val();
            numberOfDigits = value.replace(/[^0-9]/g, "").length;
            if (numberOfDigits > 9) {
                validatePhone(value);
            }
        });

        // on load check if old input exists
        if (contactPhoneField.val().length > 9) {
            validatePhone(contactPhoneField.val());
        }*/

    });

    var contactMessageMinChars = 100;
    function alertRemainingContactMessage(contactMessageLen) {



        if (contactMessageLen >= contactMessageMinChars) {
            $('#contact_message_error').hide();
            $('#remainingC').html("");
        } else if (contactMessageLen > 0) {
            $('#remainingC').html("Please include at least: " + (contactMessageMinChars - contactMessageLen) + " characters.");
        } else {
            $('#remainingC').html("");
        }
    }


    window.onContactSubmit = function onContactSubmit(token) {
        document.getElementById("contact_form").submit();
    }

    function validatePhone(phone) {
        $.get("/api/v1/phone-lookup", {
            phoneNumber: phone
        }, function (data) {
            if (data == 'valid') {
                $('#contact_phone_error').hide();
                $('#contact_submit').prop('disabled', false);
            } else {
                $('#contact_phone_error').show();
                $('#contact_submit').prop('disabled', true);
            }
        });
    }


}