'use strict'

import '../sass/app-new.scss';
//import 'tree-multiselect/dist/jquery.tree-multiselect.min.css';

// load tree-multiselect
//import 'tree-multiselect/dist/jquery.tree-multiselect.min.js';
require('tree-multiselect');
require('gasparesganga-jquery-loading-overlay');

require('./bootstrap');

require('jquery-easing');
require('jquery-validation');

// Theme
require('./theme')
require('./template')

require('./theme/stripe-menu')

import './components/pricing';
import './components/signup/brands';


// Vue
//import Vue from 'vue';
//window.Vue = Vue;

// Pages
require('./pages/signup');
require('./pages/contact');

function getPasswordValidationComponent() {
    return import(
        /* webpackChunkName: "components/password-validation" */
        './components/password-validation')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the Password Validation component');
}

function getTemplateComponent() {
    return import(
        /* webpackChunkName: "components/wizard/templates" */
        './components/signup/templates')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the Signup Templates component');
}

function getProductComponent() {
    return import(
        /* webpackChunkName: "components/wizard/products" */
        './components/signup/products')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the Signup Prodicts component');
}

function getPaymentComponent() {
    return import(
        /* webpackChunkName: "components/wizard/checkout/payment" */
        //'./components/checkout/stripe-v3-payment-intents')
        './components/checkout/stripe-v3')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the Signup Products component');
}

function getBillingComponent() {
    return import(
        /* webpackChunkName: "components/wizard/checkout/credit-card" */
        './components/signup/credit-card')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the Credit Card component');
}





// only include if we are on the signup section account info page
if (document.querySelector('.section-account-info')) {
    getPasswordValidationComponent();
}

// only include if we are on the signup section account info page
if (document.querySelector('.template-selection')) {
    getTemplateComponent();
}

// only include if we are on the signup section account info page
if (document.querySelector('.product-selection')) {
    getProductComponent();
}

// only include if we are on the signup section payment page
if (document.querySelector('.section-payment')) {
    getPaymentComponent();
    getBillingComponent();
}